import React from "react";
import type { HeadFC, PageProps } from "gatsby";
import { Container } from "../components";
import { Layout } from "../ui-lib";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setIsMobile } from "../state/mobileSlice";
import { graphql } from "gatsby";

const SkillsPage: React.FC<PageProps> = () => {
    const dispatch = useDispatch();
    const SCREEN_SIZE = 768;

    useEffect(() => {
        const handleResize = () => {
            dispatch(setIsMobile(window.innerWidth <= SCREEN_SIZE));
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [dispatch]);

    return (
        <Layout>
            <Container section="skills" />
        </Layout>
    );
};

export default SkillsPage;

export const Head: HeadFC = () => <title>Simon Morrell: Skills</title>;

export const query = graphql`
    query SkillsPageQuery {
        site {
            siteMetadata {
                title
            }
        }
    }
`;
